import { defineMessage, type MessageDescriptor } from 'react-intl';
import { compareString } from 'helpers/sort';
import { type RowCompanies } from 'report/getReport';

export type TableFilter<Row, FilterKey extends string> = {
    filterKey: FilterKey;
    messageDescriptorHeader: MessageDescriptor;
    messageDescriptorMultipleValues: MessageDescriptor;
    filterFn: (row: Row, selectedOptions: Set<string>) => boolean;
    getOptions: (rows: Row[]) => Set<string>;
};

export const categoryFilterDefinition: TableFilter<{ category: string | null }, 'category'> = {
    filterKey: 'category',
    messageDescriptorHeader: defineMessage({
        defaultMessage: 'Category',
        description: 'Filter header for category filter',
    }),
    messageDescriptorMultipleValues: defineMessage({
        defaultMessage: '{count} categories',
        description: 'Filter label for multiple category filter values',
    }),
    ...createValueFilter((row) => row.category),
};

export const networkFilterDefinition: TableFilter<{ networks: RowCompanies }, 'network'> = {
    filterKey: 'network',
    messageDescriptorHeader: defineMessage({
        defaultMessage: 'Network',
        description: 'Filter header for network filter',
    }),
    messageDescriptorMultipleValues: defineMessage({
        defaultMessage: '{count} networks',
        description: 'Filter label for multiple network filter values',
    }),
    ...createValueFilter((row) => row.networks.name),
};

export const publisherFilterDefinition: TableFilter<{ publishers: RowCompanies }, 'publisher'> = {
    filterKey: 'publisher',
    messageDescriptorHeader: defineMessage({
        defaultMessage: 'Publisher',
        description: 'Filter header for publisher filter',
    }),
    messageDescriptorMultipleValues: defineMessage({
        defaultMessage: '{count} publishers',
        description: 'Filter label for multiple publisher filter values',
    }),
    ...createValueFilter((row) => row.publishers.name),
};

export const salesNetworkFilterDefinition: TableFilter<{ networks: RowCompanies }, 'network'> = {
    filterKey: 'network',
    messageDescriptorHeader: defineMessage({
        defaultMessage: 'Sales network',
        description: 'Filter header for sales network filter',
    }),
    messageDescriptorMultipleValues: defineMessage({
        defaultMessage: '{count} sales networks',
        description: 'Filter label for multiple sales network filter values',
    }),
    ...createValueFilter((row) => row.networks.name),
};

export const salesRepresentativeFilterDefinition: TableFilter<
    { salesRepresentatives: RowCompanies },
    'salesRepresentative'
> = {
    filterKey: 'salesRepresentative',
    messageDescriptorHeader: defineMessage({
        defaultMessage: 'Sales representation',
        description: 'Filter header for sales representative filter',
    }),
    messageDescriptorMultipleValues: defineMessage({
        defaultMessage: '{count} sales representatives',
        description: 'Filter label for multiple sales representatives filter values',
    }),
    ...createValueFilter((row) => row.salesRepresentatives.name),
};

// simple filter where one column of a row is shown in alphabetical order and is filtered if in the set
function createValueFilter<Row, FilterKey extends string>(
    getValue: (row: Row) => string | null,
): Pick<TableFilter<Row, FilterKey>, 'filterFn' | 'getOptions'> {
    return {
        filterFn: (row, selectedOptions) => {
            const value = getValue(row);

            if (value === null) {
                return false;
            }

            return selectedOptions.has(value);
        },
        getOptions: (rows) => {
            return new Set(filterNull(rows.map((row) => getValue(row))).sort(compareString));
        },
    };
}

function filterNull<T>(items: T[]): NonNullable<T>[] {
    return items.filter((item) => item != null) as NonNullable<T>[];
}
